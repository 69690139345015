import React from "react";
import PropTypes from "prop-types";

import { CenterAligned, ButtonCss } from "./ChangePasswordContent.style";

import PasswordInput from "components/PasswordInput";
import Button from "components/Button";

const labels = {
  password: "Contraseña",
  passwordConfirmation: "Contraseña (confirmación)",
};

const ChangePasswordForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit}>
    <PasswordInput
      label={labels.password}
      name="password"
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.password && touched.password && errors.password}
    />
    <PasswordInput
      label={labels.passwordConfirmation}
      name="passwordConfirmation"
      value={values.passwordConfirmation}
      onChange={handleChange}
      onBlur={handleBlur}
      error={
        errors.passwordConfirmation &&
        touched.passwordConfirmation &&
        errors.passwordConfirmation
      }
    />
    <CenterAligned>
      <Button css={ButtonCss} type="submit">
        Cambiar
      </Button>
    </CenterAligned>
  </form>
);

ChangePasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
