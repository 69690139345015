import React from "react";
import { Formik } from "formik";

import { changePasswordUser } from "apiClient/users";

import withAuth from "contexts/withAuth";

import UserLayout from "layouts/UserLayout";

import ChangePasswordContent from "pagesComponents/ChangePassword/ChangePasswordContent";
import ChangePasswordSuccess from "pagesComponents/ChangePassword/ChangePasswordSuccess";
import PrivateLayout from "layouts/PrivateLayout";

const getInitialValues = (filledValues) => ({
  password: "",
  passwordConfirmation: "",
  ...filledValues,
});

class ChangePasswordPage extends React.PureComponent {
  state = {
    errors: {},
    sent: false,
  };

  validate = (values) => {
    let errors = {};

    // Password confirmation
    if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = "Las contraseñas no coinciden";
    }

    // All fields required
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        errors[key] = "Requerido";
      }
    });

    return errors;
  };

  onChangeName = (name) => {
    this.setState((state) => {
      const currentErrors = state.errors;
      delete currentErrors[name];

      return { errors: { ...currentErrors } };
    });
  };

  onSubmit = async (values) => {
    try {
      await changePasswordUser(this.props.session.email, values.password);

      this.setState({
        sent: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { sent } = this.state;

    if (sent) {
      return (
        <PrivateLayout layout={UserLayout} title="Cambiar contraseña">
          <ChangePasswordSuccess />
        </PrivateLayout>
      );
    }

    return (
      <PrivateLayout layout={UserLayout} title="Cambiar contraseña">
        <Formik
          initialValues={getInitialValues()}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {({ errors, handleChange, ...formikProps }) => (
            <ChangePasswordContent
              {...formikProps}
              errors={{ ...errors, ...this.state.errors }}
              handleChange={(event) => {
                this.onChangeName(event.target.name);
                handleChange(event);
              }}
            />
          )}
        </Formik>
      </PrivateLayout>
    );
  }
}

export default withAuth(ChangePasswordPage);
