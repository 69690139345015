import React from "react";
import { Link } from "gatsby";

import { CATEGORIES_ROUTE } from "@routes";

const ChangePasswordSuccess = () => (
  <>
    <p>Se ha actualizado su contraseña.</p>
    <Link to={CATEGORIES_ROUTE}>Ir de compras.</Link>
  </>
);

export default ChangePasswordSuccess;
